/*******************************
         Site Overrides
*******************************/
html {
    overflow-y:hidden;
}
body {
    overflow-y:hidden;
}
.ui.grid > .row.nav-section {
    padding-bottom:0;

    .nav-bar{
        border-bottom:1px solid #092D74;
    }

}

.ui.grid > .row.sidebar-section {

    background:#F1F1F1;
    height:calc(100vh - 60px);
    padding:0 0 0;

    .sidebar {
        background:#092D74;
        padding-right:0;
        min-width:240px;
    }

}

.ui.grid > .row > .column.content-section {
    padding-top:1em;
    padding-bottom:1em;
    width:calc(100vw - 226px );
    height:calc(100vh - 60px );
    overflow-y:scroll;
}

.listing-grid {

    .react-grid-Main{
        outline:1px solid transparent;
        outline-top: 1px solid #e7eaec;
    }
    .react-grid-Container{
        padding: 0px;
    }

    .react-grid-Grid{
        border:1px solid transparent;
        height:calc(100vh - 302px );
    }

    .react-grid-Cell {
        border-right: 1px solid #dddddd;
    }

    .react-grid-Header{
        border-bottom:1px solid #dddddd;
    }

    .react-grid-Canvas{
        background:#fff;
    }

    .react-grid-checkbox-label{
        line-height: 1em;
    }

    .rdg-selected {
        border: 2px solid transparent;
    }
}